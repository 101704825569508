import React, { FC, useEffect, useState, useRef } from 'react'
import SelectOption, { ISelectOption } from 'src/components/ui/SelectOption'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import ArrowIcon from 'src/components/icons/ArrowIcon'

interface ISelectProps {
  options: ISelectOption[]
  isSelectedAllAsDefault?: boolean
  placeholder: string
  className: string
  selectedItem: any
  onSelect: (option: ISelectOption) => any
}

export const defaultOptionId = 'all'

const Select: FC<ISelectProps> = ({
  options,
  placeholder,
  className,
  onSelect,
  selectedItem,
  isSelectedAllAsDefault,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  const handleOptionClick = (option: ISelectOption) => {
    onSelect(option)
    setIsOpen(false)
  }

  const optionsWithDefault: ISelectOption[] = [
    {
      optionId: defaultOptionId,
      label: placeholder,
    },
    ...options,
  ]

  const getOptions = () =>
    optionsWithDefault.map((option) => (
      <SelectOption
        isSelected={selectedItem && selectedItem.optionId === option.optionId}
        key={option.optionId}
        option={option}
        onClick={() => handleOptionClick(option)}
      />
    ))

  const handleDocumentClick = (event) => {
    const isClickedOutside =
      selectRef.current && !selectRef.current.contains(event.target)

    if (isClickedOutside) {
      setIsOpen(false)
    }
  }

  const selectFirstOptionAsDefault = () => {
    onSelect(optionsWithDefault[0])
  }

  useEffect(() => {
    if (isSelectedAllAsDefault) {
      selectFirstOptionAsDefault()
    }
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const getArrowRotate = () => {
    if (isOpen) {
      return 'top'
    }

    return 'bottom'
  }

  return (
    <div className={`relative select ${className}`} ref={selectRef}>
      <button
        type="button"
        aria-label="Open or close selector"
        onClick={() => setIsOpen(!isOpen)}
        className={`
        bg-deepDarkBlue text-brightYellow px-2 py-1 w-full
        text-left text-xl lg:text-base font-bold tracking-wider flex items-center justify-between
        `}
      >
        <p>
          {selectedItem ? selectedItem.label : placeholder || 'SELECT ITEM...'}
        </p>
        <ArrowIcon rotate={getArrowRotate()} />
      </button>
      <HeightAnimation
        isOpen={isOpen}
        className="absolute w-full z-30"
        duration={0.25}
      >
        <ul className="max-h-48 overflow-y-auto">{getOptions()}</ul>
      </HeightAnimation>
    </div>
  )
}

export default Select
