// i18next-extract-mark-ns-start documents

import React, { FC, useContext } from 'react'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'
import PdfDocumentSvg from 'src/images/icons/pdf-document.inline.svg'
import DownloadSvg from 'src/images/icons/download.inline.svg'
import styled from 'styled-components'
import { IRelatedDocument } from 'src/components/document/document-types'

interface IColouredProps {
  color: string
}

const Coloured = styled.div<IColouredProps>`
  color: ${(props) => props.color};
  border-color: ${(props) => props.color};
`

interface IRelatedDocumentItemProps {
  document: IRelatedDocument
  color: string
  className?: string
  isBlack?: boolean
  type?: 'link'
}

const RelatedDocumentItem: FC<IRelatedDocumentItemProps> = ({
  document,
  color,
  className,
  isBlack,
}) => {
  const { t } = useTranslation('documents')
  const lngContext = useContext(I18nextContext)
  const url = document.externalLink
    ? document.externalLinkUrl
    : `/downloads/${document.slug}?l=${lngContext.language}`
  const actionText = t('Download')
  return (
    <Coloured
      color={color}
      className={`leading-tight text-lg ${className || ''}`}
    >
      <a
        className="flex justify-between py-4"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex items-center">
          <PdfDocumentSvg
            className={`self-center w-6 h-7 mr-3 flex-shrink-0 ${
              isBlack ? 'text-deepDarkBlue' : 'text-navyBlueGray'
            }`}
            aria-label="article"
          />
          <p className="self-center text-left">{document.name}</p>
        </div>
        <div className="flex ml-14 relative items-center">
          <p
            className={`
              self-center whitespace-nowrap mr-6 hidden lg:block
              ${isBlack ? 'text-deepDarkBlue' : 'text-navyBlueGray'}`}
          >
            {actionText}
          </p>
          <DownloadSvg
            title={actionText}
            aria-label={actionText}
            className={`w-5 h-6 max-w-none block stroke-current flex-shrink-0 ${
              isBlack ? 'text-deepDarkBlue' : 'text-navyBlueGray'
            }`}
          />
        </div>
      </a>
    </Coloured>
  )
}

export default RelatedDocumentItem
