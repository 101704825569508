import React, { FC } from 'react'
import styled from 'styled-components'

export interface ISelectOption {
  displayDocumentsIcons?: boolean
  optionId: string
  label: string | JSX.Element | JSX.Element[]
}
export interface ISelectOptionProps {
  option: ISelectOption
  onClick: () => any
  isSelected: boolean
}

const StyledListItem = styled.li`
  &:last-child {
    border-bottom: none;
  }
`

const SelectOption: FC<ISelectOptionProps> = ({
  option,
  onClick,
  isSelected,
}) => (
  <StyledListItem
    id={option.optionId}
    className="border-grayBlue border-b cursor-pointer bg-paleBlue hover:bg-navyBlueGray transition-all duration-300"
  >
    <button
      type="button"
      className={`
        px-2 py-1 w-full text-left
        ${isSelected ? 'font-bold' : 'font-base'}
      `}
      onClick={onClick}
    >
      {option.label}
    </button>
  </StyledListItem>
)

export default SelectOption
