import React, { FC } from 'react'
import { IRelatedDocument } from 'src/components/document/document-types'
import RelatedDocumentItem from 'src/components/document/RelatedDocumentItem'
import styled from 'styled-components'

interface IColouredProps {
  color: string
}

const Coloured = styled.div<IColouredProps>`
  color: ${(props) => props.color};
`

interface IRelatedDocumentsProps {
  items: IRelatedDocument[]
  header?: string
  color: string
  className?: string
  noTopBorder?: boolean
  noBottomBorder?: boolean
  isBlack?: boolean
}

const RelatedDocuments: FC<IRelatedDocumentsProps> = ({
  items,
  header,
  color,
  className,
  noTopBorder,
  noBottomBorder,
  isBlack,
}) => {
  if (!items) {
    return <></>
  }

  return (
    <Coloured color={color} className={className}>
      {header && <h3 className="text-lg font-bold pb-2">{header}</h3>}
      {items.map((item, index) => (
        <RelatedDocumentItem
          key={item.id}
          document={item}
          color={color}
          isBlack={isBlack}
          className={`
            ${noBottomBorder ? '' : 'border-b'}
            ${noTopBorder || index ? '' : 'border-t'}
          `}
        />
      ))}
    </Coloured>
  )
}

export default RelatedDocuments
